import React, { useEffect, useState } from 'react';
import { TextField, Paper, useTheme, Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { rhythm, AllowInputNumber } from 'src/utils';

const Title = styled.p`
  border-bottom: 0.5px solid;
  margin-bottom: ${rhythm(1)};
`;

const schema = yup.object({
  weightInKg: yup
    .string()
    .matches(/^[0-9\.]+$/g, 'เฉพาะตัวเลขเท่านั้น')
    .required('กรุณากรอกข้อมูล'),
  hightInCm: yup
    .string()
    .matches(/^[0-9\.]+$/g, 'เฉพาะตัวเลขเท่านั้น')
    .required('กรุณากรอกข้อมูล'),
});

interface BMIProps {
  weightInKg: number;
  hightInMeter: number;
}

function BmiCalculator() {
  const theme = useTheme();
  const { control, watch, register, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [bmi, setBmi] = useState<any>();

  const { errors } = useFormState({ control });

  useEffect(() => {
    const weightInKg = +getValues('weightInKg');
    const hightInCm = +getValues('hightInCm');

    if (!!!hightInCm || hightInCm < 0 || !!!weightInKg || weightInKg < 0) {
      return;
    }

    const hightInMeter = Number((hightInCm / 100).toFixed(2));

    const calBMI = (props: BMIProps) =>
      props.weightInKg / (props.hightInMeter * props.hightInMeter);
    function resultBMI(bmi: number): string {
      if (bmi >= 30) {
        return `BMI ${bmi.toFixed(
          2
        )} อยู่ในภาวะอ้วนอย่างมากและเสี่ยงต่อการเผชิญปัญหาสุขภาพที่รุนแรง`;
      }

      if (bmi >= 25 && bmi < 30) {
        return `BMI ${bmi.toFixed(
          2
        )} อยู่ในภาวะอ้วนที่เสี่ยงต่อการเกิดปัญหาสุขภาพ`;
      }

      if (bmi >= 23 && bmi < 25) {
        return `BMI ${bmi.toFixed(2)} มีน้ำหนักเกิน`;
      }

      if (bmi >= 18.5 && bmi < 23) {
        return `BMI ${bmi.toFixed(2)} อยู่ในเกณฑ์สุขภาพดี`;
      }

      if (bmi < 18.5) {
        return `BMI ${bmi.toFixed(2)} มีน้ำหนักต่ำเกณฑ์`;
      }
      return '';
    }

    setBmi(resultBMI(calBMI({ weightInKg, hightInMeter })));
  }, [watch(), setBmi]);

  return (
    <Paper variant="outlined" style={{ padding: rhythm(1) }}>
      <form autoComplete="off">
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Title>ข้อมูล</Title>
            <TextField
              inputProps={register('hightInCm')}
              inputMode="numeric"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: 'เซนติเมตร' }}
              placeholder="พิมพ์ส่วนสูงเป็นเซนติเมตร"
              label="ส่วนสูง"
              error={!!errors?.hightInCm && !!errors?.hightInCm?.message}
              helperText={
                errors?.hightInCm ? (
                  <span style={{ color: theme?.palette?.error?.main }}>
                    {errors?.hightInCm && errors?.hightInCm?.message}
                  </span>
                ) : (
                  <span>กรอกเฉพาะตัวเลขเท่านั้น</span>
                )
              }
              fullWidth
              style={{ marginBottom: rhythm(1) }}
            />
            <TextField
              inputProps={register('weightInKg')}
              inputMode="numeric"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: 'กิโลกรัม' }}
              placeholder="พิมพ์น้ำหนักเป็นกิโลกรัม"
              label="น้ำหนัก"
              onKeyPress={AllowInputNumber}
              error={!!errors?.weightInKg && !!errors?.weightInKg?.message}
              helperText={
                errors?.weightInKg ? (
                  <span style={{ color: theme?.palette?.error?.main }}>
                    {errors?.weightInKg && errors?.weightInKg?.message}
                  </span>
                ) : (
                  <span>กรอกเฉพาะตัวเลขเท่านั้น</span>
                )
              }
              fullWidth
              style={{ marginBottom: rhythm(1) }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Title>ผลลัพท์</Title>
            <p
              style={{
                textAlign: 'center',
              }}
            >
              {bmi}
            </p>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default BmiCalculator;
