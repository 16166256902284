import React from "react";
import Helmet from "react-helmet";
import { Container } from "@material-ui/core";
import { NavBar } from "../components/NavBar";
import BMICalculator from "../components/BmiCalculator";

function BmiCalculatorPage() {
  return (
    <>
      <Helmet>
        <title>โปรแกรมคำนวณ BMI ออนไลน์ — Calculate Anything</title>
        <meta
          name="description"
          content="ตัวช่วยคำนวณดัชนีมวลกายออนไลน์ ประกอบการตัดสินใจ"
        />
      </Helmet>
      <Container maxWidth="lg">
        <NavBar />
        <h1>โปรแกรมคำนวณดัชนีมวลกายออนไลน์ (BMI)</h1>
        <section>
          {typeof window !== `undefined` ? <BMICalculator /> : <>Loading..</>}
        </section>
      </Container>
    </>
  );
}

export default BmiCalculatorPage;
